import en from 'vuetify/es5/locale/en';
import attractions from '@/locales/en/attractions';

export default {
  ...en,

  attractions,
  mainNav: {
    destinations: 'Destinations',
    events: 'Events',
    travels: 'Travels',
    information: 'Information',
    catalog: 'Digital catalog',
  },
  search: {
    title: 'Find your trip',
    btn: 'Search',
  },
  subscribe: {
    btn: 'Subscribe',
    placeholder: 'Email address',
  },
  footer: {
    follow: 'Follow us',
    info: 'Information',
    returnPolicy: 'Return policy',
    insurance: 'insurance',
    contacts: 'Contacts',
    contact: 'Contact us',
    about: 'about us',
  },
  travel: {
    egypt: 'Egypt',
    turkey: 'Turkey',
    india: 'India',
    jordan: 'Jordan',
    emirates: 'EmiratesArabs',
    israel: 'Israel',
    download: 'Download',
    watch: 'Watch online',
    share: 'Share',
    titleCards: 'Featured Travel',
    titleText: 'Why Aviaral Travel?',
    text: '',
  },
  whyUs: {
    title: 'Why Aviaral Travel?',
    p1: '“It is much more rewarding to reach the top of the mountain and share your experience with others than to show yourself exhausted.”',
    p2: 'From the beginning we had the opportunity to create our 5 A website with a cultural objective (2016), but now our intention is to be able to share this experience by merging the "tourist" and the "cultural". We want to offer our knowledge as well as all the experience acquired over the years working in the tourism sector.',
    p3: 'One of the most important factors in a company is to believe in what we do. Each member of the team is very clear about his role in the agency and his role as a traveler.',
    p4: 'We have a team prepared to provide the level of satisfaction that a traveler is demanding, especially because each of the team made a trip to these listed destinations.',
    p5: 'Some of our partners are citizens of the destination countries, something that makes it easier for us to be aware of the news of the listed countries',
    p6: 'We offer private departure, individual trips, small groups and exclusive Aviaral Travel trips. This reflects our flexibility and variety in our product, being able to make tailor-made trips, making them unique and unrepeatable. (Check out our who we are page). We have the ability to welcome the deaf, disabled, even the blind and develop travel projects for them, we think and want to eliminate social barriers.',
    h3: 'Why travel with Aviaral Travel?',
    tab1: {
      title: 'Local guides',
      text: 'We have fully prepared local guides and knowledge of languages.',
    },
    tab2: {
      title: 'Service and accommodation',
      text: 'Our services are packages to the destination in different categories taking into account the difference in the quality of the categories between Europe and the chosen destination.',
    },
    tab3: {
      title: 'Easy answer',
      text: 'In a world that does not find an easy answer, Aviaral Travel wants to offer you a comfortable and safe way to purchase your trip, you can purchase your trip on our website and we also offer you personalized attention at our headquarters located in Malaga. We also have guides in Spain and Latin America providing security and problem solving for any unforeseen event. Our goal is to achieve, together with our clients, the best experience of your trip.',
    },
  },
  destinations: {
    egypt: 'Egypt',
    turkey: 'Turkey',
    india: 'India',
    jordan: 'Jordan',
    emirates: 'Arab Emirates',
    israel: 'Israel',
    slogan: 'eslogan para País',
    attractions: 'Attractions',
  },
  faqEgypt: {
    q1: {
      q: 'What are the requirements for the Egyptian visa?',
      a: '<p>Egypt visa is mandatory for all visitors for tourist purposes, either through ports or airports </p><p>The Egyptian visa has 2 types, one of a ticket only, it is valid for 30 days in the country and expiration date as many 3 months, there is also another visa that multi tickets, and is valid for 3 months but also requires an exit from the country every 30 days</p><p>Demands for entry </p><ol class="mb-4"><li>Ordinary passport in force for 7 months (diplomatic passport needs advance visa</li><li>Travellers for tourist purposes must have a hotel reservation or their place of stay in Egypt, in case of trips organized with travel agencies is commissioned by the agency staff who remain present at the airports</li></ol><p>To all our aviaral travel travelers we have our itineraries and routes with the visa included always so we save burden and hassle</p>',
    },
    q2: {
      q: 'What vaccines do you need to enter the country?',
      a: '<p>No vaccine is required upon entry, although it depends on the season, arrival flight is sometimes required yellow fever vaccine certificate from countries that are considered the seat of the disease</p>',
    },
    q3: {
      q: 'What clothes should a tourist wear in Egypt?',
      a: '<p>Packing a suitcase to Egypt is very simple, depending very much on the weather, and the customs </p><p>Egypt is a country throughout its history it was civil, I never carry a religious system, although I arrive this system in 2011 but not hard more than an anus, but still the social classes are very marked and it is usually the middle class, and humble quite preserved, since much of the archaeological moments are in areas of humble class and middle class , which</p><p><strong>For women</strong> It is advisable to wear clothes to Egypt of women with short or long sleeves without necklines and long pants or shorts not so sexy, but also in hotels, cruise you can wear the clothes you want, in the sea areas are very Western cities where you can wear what you want, when entering the mosques you usually do not cover your head although in some mosques if asked </p><p><strong>For men</strong> men\'s clothes in Egypt although they are not similar to Western men but still do not carry any offense any way of dressing man </p>',
    },
    q4: {
      q: 'Clothes according to the climate of Egypt?',
      a: '<p>Egypt is one of the countries that receives a lot of climate change like many parts of the world but it is still advisable in summer cotton clothing and in winter 3 layers because Egypt goes through 3 phases of temperature during the day, during the morning quite cold, then gradually feels warmer and at night it is quite cold especially in the north of the country.</p>',
    },
    q5: {
      q: 'What is Egypt\'s climate like?',
      a: '<p>Despite its dry environment but due to its varied nature between desert, fertile valley (the Nile River valley and its delta) 2 seas that are the red sea and the Mediterranean Sea, and mountains, Egypt\'s climate is changed from one area to the other. But it joins in the country not getting much rain in much of its territory except in the north in the winter months between November and March, rarely Egypt receives snow and is usually in the east both in Sinai and the delta cities that are usually quite humid.</p><p>The summer months in Egypt are from April to October while the winter months are usually from November to March </p><p>Due to climate change, Egypt was also one of the countries suffering from climate change that was on the one positive side and on the other negative, formerly the summer of the Pharaonic country was quite hot that reached 50 degrees both in the south and in the desert territory, today Egypt has been with a much more relieved summer with about 40 degrees or 39 in the warmer months (July and August) and dawned winter of this country much longer and colder than before, that I arrive the north of this country in the coldest winter to have 8 or 7 degrees while the south reaches about 15 degrees or 12 at some times of the day, but on the other hand the country does not yet have enough infrastructure to receive rain in the north and is an additional charge to the regime established now </p>',
    },
    q6: {
      q: 'What is The Currency of Egypt?',
      a: '<p>Egypt\'s currency is the Egyptian pound, it remained a high purchasing power until the 1950s until some point in its history came almost equal to a pound of gold, starting in the 1960s due to the political situation after receiving a military coup in the 1950s, and the constant wars, the Suez crisis , the 6-day war, the war of \'69 and finally the yum kipur war, the Egyptian currency took many blows caused its devaluation to become almost 7 pounds in exchange for the dollar, and 9 pounds in exchange for the euro.</p><p>The pound continued to bleed from the political situation after 2 revolutions known as the first Arab vera, and the regime established in Egypt faced the strongest economic crisis in the history of the millennial country, it dawned forced to devalue the currency, since then the pound began to lose a lot of value against the dollar that reached 15.5 Egyptian pounds , and against the euro I reach 19 Egyptian pounds </p><p>Today despite the economic situation, Egypt\'s economy is considered one of the best growing globally, but even the country has many obstacles and problems to solve.</p>',
    },
    q7: {
      q: 'Can you pay by card in Egypt?',
      a: '<p>You can pay with all kinds of credit card in Egypt although american express does not take preference for your high commission (it is not advised to pay by credit card at unerned sites in Egypt)</p>',
    },
    q8: {
      q: 'How to exchange money in Egypt, and one must exchange money before traveling to Egypt?',
      a: '<p>The Egyptian monkey is the Egyptian pound, it was devalued a few years ago by the economic crisis that Egypt suffered during the time of the first arab see, much lower the value of the Egyptian liberate against the dollar and the euro.</p><p>It is always advised to exchange money in Egypt that always change is better there, all hotels usually have banks to change but there are cashiers to exchange money and exchange houses near archaeological monuments </p><p>The price against the Egyptian pound </p><ol><li>Dollar between 15.00 and 16.00 Egyptian pounds </li><li>Euro between 18.00 and 19.00 Egyptian pounds </li></ol>',
    },
    q9: {
      q: 'What is the best season to go to Egypt?',
      a: '<p>In the old days, the best time was from November to April but due to climate change the season lasted from September to April, with a summer bearable to certain nationalities </p>',
    },
    q10: {
      q: 'Can a single woman travel to Egypt?',
      a: '<p>Although the Egyptian world is apparently more masculine, but the role of women in Egyptian society was very remarkable, something manifested itself in Egypt\'s last elections. And he had the candidates direct the political discourse to them, that answers the question that the woman being alone can quietly make a trip to Egypt</p>',
    },
    q11: {
      q: 'Where can I find a complete guide to Egypt?',
      a: '<p>At present there are so many guides on the market on Egypt that one more may seem unnecessary however I hope that ours will be interesting, it is different in the sense of the topics it reveals, it also points out the sites to try to understand the purpose for which these sites were intended, to vary the vision about the destination and choose the package that suits us this destination , it also contains some itineraries of ours apart from the itineraries that are on our website </p><p>Egypt\'s guide is arranged in an interesting way that starts with its index, most important cities, then some interesting articles about certain sites that we focus to present you in a way that deserves, then shows our way to split the travel packages benefits, also counts on our insurance and how to travel insurance both health insurance, cancellation and suitcases.</p>',
    },
    q12: {
      q: 'Is Egypt considered a safe country?',
      a: '<p>Throughout its history even during the time of the wars Egypt has been a safe destination, and according to its world ranking it had a good security rating worldwide and that because security itself is an objective of the regime established in Egypt, after quite a few manifestations, political situation, radical economic measures, the police presence is seen in the country , also the system, perhaps inspires many people discomfort but in reality the Egyptian country is establishing various tax collection systems, devaluing the currency to change the system from a left socialist system to capitalist and that requires the state to be attentive to various needs especially the security and humble class of the country and its needs.</p>',
    },
  },
  card: {
    from: 'from',
    more: 'more info',
    days: 'days',
    nights: 'nights',
  },
  tour: {
    days: 'days',
    day: 'Day',
    nights: 'nights',
    regularTrips: 'regular trips',
    description: 'Description',
    include: 'It includes',
    notInclude: 'Not include',
    trip: 'Visits during the trip',
    notes: 'Notes',
    itinerary: 'Travel itinerary',
    price: 'Categories and prices',
    childPolicy: {
      title: 'Child Policy',
      li1: 'From 0 to 1.99 - 25% of the price',
      li2: 'From 2 to 11.99 - 50% of the price',
      li3: '+12 - 100% of the trip',
    },
    tourist: 'Tourist',
    luxury: 'Luxury',
    superior: 'Luxury superior',
    top: 'Top  luxury',
    priceDouble: 'Per Person in Double Room',
    single: 'Single supplement',
    faq: 'FAQ',
  },
  tours: {
    egypt: {
      egyptAndMarsaAlam: {
        title: 'Egypt and marsa alam',
        intro: '<p>Traveling to the red sea is a cheap luxury trip compared to similar destinations, marsa alam also called the blue lagoon is one of the very interesting destinations in the best red, but it is not very well offered, marsa alam is one of the few cities of the Red Sea that is still a virgin city and its sea has many riches where you can do many activities especially for honeymooners</p>',
        include: {
          li1: '3 nights in marsa alam all inclusive regime',
          li2: '4 nights cruise on the Nile (breakfast, lunch and dinner)',
          li3: '3 nights in Cairo (breakfast regime)',
          li4: 'Transportation and visit according to the itinerary',
          li5: 'Domestic flights',
          li6: 'Fees and taxes',
          li7: 'Cancellation insurance',
          li8: 'Visa',
          li9: 'Accompanying guide',
        },
        notInclude: {
          li1: 'Visits not mentioned in the itinerary',
          li2: 'Travel tips',
          li3: 'Tips from the accompanying guide',
          li4: 'Suitcase insurance, health',
          li5: 'International flight',
        },
        trip: {
          li1: 'Marsa alam. Excursions by the sea will be offered by our staff (optional)',
          li2: 'Luxor, Karnak Temple, Luxor Temple, Hatshpsut Temple, Valley of the Kings',
          li3: 'Aswan, the temple of edfu, the temple of kom ombo, the incubated obelisk, the high dam',
          li4: 'Cairo the pyramids of giza (Cheops, kefren and mycerin), the temple of the valley of kefren, the sphinx',
        },
        notes: {
          li1: 'Regular aviaral travel trips',
          li2: 'Those who want to have an organized trip the entire route with a well-prepared travel companion',
          li3: 'They are usually shared trips with other travelers; it may be of the same nationality or of another nationality speaking the same language',
        },
        time: {
          d1: {
            title: 'All inclusive',
            text: 'Arrival at the airport of marsa alam or hurgada, which is the second closest airport to marsa alam, assistance with visa procedures, transfer to the hotel, accommodation in marsa alam, you can get to any airport simply the order of the itinerary will receive changes',
          },
          d2: {
            title: 'All inclusive',
            text: 'Free day, possibility of diving or snorkeling in the Red Sea where there can be many collars and different types of times (they will be offered by our staff',
          },
          d3: {
            title: 'All inclusive',
            text: 'Free day, possibility of doing activity to go see the dolphins, or go on a safari through the valleys of the desert of marsa alam',
          },
          d4: {
            title: 'All inclusive',
            text: 'Free day, possibility to do a city tour through the city of marsa alam, or go to know the city of hurgada (approx 2 hours) from marsa alam',
          },
          d5: {
            title: 'Marsa alam-luxor',
            text: 'Breakfast, departure in the car to Luxor (approx 5 hours), arrival and accommodation on the cruise, in the afternoon departure to visit the Temple of Karnak, the famous temple dedicated to the god Amon, and one of the largest temples in Egypt, visit to the temple of luxor, the royal house of the god Amon, night on board in luxor',
          },
          d6: {
            title: 'Luxor-edfu',
            text: 'To visit the most important necropolis in Egypt which is the Valley of the Kings, where the tomb of Tutankhamun was found after a search of more than 22 years by the famous hawered Carter, we proceed to visit the temple of hatshpsut, the funerary temple of the queen hatshpsut, dedicated to the god amon, one of the best architectures of ancient Egypt, visit deir al madina where the elite of artisans lived and present the first strike of humanity, we follow the colossi of memnon .back to the ship, navigation to esna, night on board in edfu',
          },
          d7: {
            title: 'Edfu-kom ombop',
            text: 'After breakfast, departure in horse carts to visit the temple of Edfu dedicated to the famous Egyptian god Falcon Horus, return to the cruise. Lunch and navigation to Kom ombo. In the afternoon visit to the temple of kom ombo dedicated to the crocodile god Sobek. Return to the cruise. And navigation to the city of Aswan. Dinner and night on board in Aswan.',
          },
          d8: {
            title: 'Aswan',
            text: 'Breakfast. Possibility to know the temple of Aabu simbel, carved in the rock, and transferred to its current place in one of the miracles of human engineering, in the afternoon ride in a falucca and possibility to know the Nubian people, the African race that focus more on Aswan close to its Sudanese and Ethiopian origins',
          },
          d9: {
            title: '',
            text: 'Breakfast, Departure to know the high dam of Aswan, the work that saved Egypt from the overflowing of the Nile and made possible permanent irrigation to the current Egyptian lands, visit the unfinished obelisk the best known granite quarry of ancient Egypt and the place where some were carved stones of the great pyramid, in the afternoon flight to Cairo, arrival assistance and transfer to the hotel, night in Cairo',
          },
          d10: {
            title: '',
            text: 'Breakfast. Visit to the wonders of the ancient world "the pyramids of Cheops, Kefren and Menkaure, the temple of the valley and the great Sphinx, possibility to know Sakkara the first necropolis in Egyptian history where the first pyramid in the history of mankind is found, and also the pyramids of dahshur. the first attempts to have a pyramid in normal shape with one unsuccessful attempt being the bent pyramid and another successful one being the red pyramid',
          },
          d11: {
            title: '',
            text: 'Breakfast, possibility to visit the Cairo Museum, the headquarters of the King Tut Ankhamon complex, the Saladin citadel where the Mohamed Ali Mosque is located, and the Khan el Khalili market',
          },
          d12: {
            title: '',
            text: 'Breakfast, transfer to the airport to pick up the flight on the way back home<br> End of service',
          },
        },
        prices: {
          intro: 'Our prices are for a minimum of 2 people, there is a supplement for singles, and also for certain dates such as Christmas, New Year\'s Eve and Easter',
        },
        accommodation: {
          marsaAlam: 'Accommodation<br> marsa alam',
          nile: 'Nile cruise',
          cairo: 'Accommodation<br> in Cairo',
        },
      },
      cruiseInTheRedSea: {
        title: 'Cruise on board a yacht <br>in the Red Sea',
      },
    },
  },
};
