function getImgMap(imgReq) {
  return imgReq.keys()
    .reduce((images, path) => {
      const key = path.substring(path.lastIndexOf('/') + 1, path.lastIndexOf('.'));
      // eslint-disable-next-line no-param-reassign
      images[key] = imgReq(path);
      return images;
    }, {});
}

const flags = getImgMap(require.context('../assets/flags', false, /\.svg$/));
const homeSlider = getImgMap(require.context('../assets/home/slider', false, /\.jpg$/));
const homeCards = getImgMap(require.context('../assets/home/cards', false, /\.jpg$/));
const sakkara = getImgMap(require.context('../assets/blog/sakkara', false, /\.jpg$/));
const blogEgypt = getImgMap(require.context('../assets/blog/egypt', false, /\.jpg$/));

export {
  flags,
  homeSlider,
  homeCards,
  sakkara,
  blogEgypt,
};
