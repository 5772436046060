import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/destinations',
    name: 'Destinations',
  },
  {
    path: '/destinations/egypt',
    name: 'DestinationsEgypt',
    component: () => import(/* webpackChunkName: "dest-egypt" */ '../views/DestinationsEgypt.vue'),
  },
  {
    path: '/destinations/egypt/egypt-and-marsa-alam',
    name: 'EgyptAndMarsaAlam',
    component: () => import(/* webpackChunkName: "egypt-and-marsa-alam" */ '../views/egypt/EgyptAndMarsaAlam.vue'),
  },
  {
    path: '/events',
    name: 'Events',
    component: () => import(/* webpackChunkName: "events" */ '../views/Events.vue'),
  },
  {
    path: '/travels',
    name: 'Travels',
    component: () => import(/* webpackChunkName: "travels" */ '../views/Travels.vue'),
  },
  {
    path: '/information',
    name: 'Information',
    component: () => import(/* webpackChunkName: "information" */ '../views/Information.vue'),
  },
  {
    path: '/information/abidos',
    name: 'Abidos',
    component: () => import(/* webpackChunkName: "abidos" */ '../views/blog/Abidos.vue'),
  },
  {
    path: '/information/alexandria',
    name: 'Alexandria',
    component: () => import(/* webpackChunkName: "alexandria" */ '../views/blog/Alexandria.vue'),
  },
  {
    path: '/information/aswan',
    name: 'Aswan',
    component: () => import(/* webpackChunkName: "aswan" */ '../views/blog/Aswan.vue'),
  },
  {
    path: '/information/beni-hasane',
    name: 'BeniHasane',
    component: () => import(/* webpackChunkName: "beni-hasane" */ '../views/blog/BeniHasane.vue'),
  },
  {
    path: '/information/cairo',
    name: 'Cairo',
    component: () => import(/* webpackChunkName: "cairo" */ '../views/blog/Cairo.vue'),
  },
  {
    path: '/information/kom-el-shouqafas-catacombs',
    name: 'KomElShouqafasCatacombs',
    component: () => import(/* webpackChunkName: "kom-el-shouqafas-catacombs" */ '../views/blog/KomElShouqafasCatacombs.vue'),
  },
  {
    path: '/information/caitbey-city-and-lighthouse',
    name: 'CaitbeyCity',
    component: () => import(/* webpackChunkName: "caitbey-city-and-lighthouse" */ '../views/blog/CaitbeyCity.vue'),
  },
  {
    path: '/information/saladine-citadel',
    name: 'SaladineCitadel',
    component: () => import(/* webpackChunkName: "saladine-citadel" */ '../views/blog/SaladineCitadel.vue'),
  },
  {
    path: '/information/dakhla-oasis',
    name: 'DakhlaOasis',
    component: () => import(/* webpackChunkName: "dakhla-oasis" */ '../views/blog/DakhlaOasis.vue'),
  },
  {
    path: '/information/deir-medina',
    name: 'DeirMedina',
    component: () => import(/* webpackChunkName: "deir-medina" */ '../views/blog/DeirMedina.vue'),
  },
  {
    path: '/information/the-roman-amphiteater',
    name: 'RomanAmphiteater',
    component: () => import(/* webpackChunkName: "the-roman-amphiteater" */ '../views/blog/RomanAmphiteater.vue'),
  },
  {
    path: '/information/el-ashmuneina',
    name: 'Ashmunein',
    component: () => import(/* webpackChunkName: "ashmunein" */ '../views/blog/Ashmunein.vue'),
  },
  {
    path: '/information/coptic-cairo',
    name: 'CopticCairo',
    component: () => import(/* webpackChunkName: "coptic-cairo" */ '../views/blog/CopticCairo.vue'),
  },
  {
    path: '/information/the-fayoum',
    name: 'Fayoum',
    component: () => import(/* webpackChunkName: "fayoum" */ '../views/blog/Fayoum.vue'),
  },
  {
    path: '/information/minya',
    name: 'Minya',
    component: () => import(/* webpackChunkName: "minya" */ '../views/blog/Minya.vue'),
  },
  {
    path: '/information/the-nubian-museum',
    name: 'NubianMuseum',
    component: () => import(/* webpackChunkName: "nubian-museum" */ '../views/blog/NubianMuseum.vue'),
  },
  {
    path: '/information/unfinished-obelisk-of-aswan',
    name: 'UnfinishedObeliskAswan',
    component: () => import(/* webpackChunkName: "unfinished-obelisk-of-aswan" */ '../views/blog/UnfinishedObeliskAswan.vue'),
  },
  {
    path: '/information/the-nubian-village',
    name: 'NubianVillage',
    component: () => import(/* webpackChunkName: "the-nubian-village" */ '../views/blog/NubianVillage.vue'),
  },
  {
    path: '/information/filae-temple',
    name: 'FilaeTemple',
    component: () => import(/* webpackChunkName: "filae-temple" */ '../views/blog/FilaeTemple.vue'),
  },
  {
    path: '/information/farafra-oasis',
    name: 'FarafraOasis',
    component: () => import(/* webpackChunkName: "farafra-oasis" */ '../views/blog/FarafraOasis.vue'),
  },
  {
    path: '/information/hurgada',
    name: 'Hurgada',
    component: () => import(/* webpackChunkName: "hurgada" */ '../views/blog/Hurgada.vue'),
  },
  {
    path: '/information/khan-el-khalilli-market',
    name: 'KhanKhalilliMarket',
    component: () => import(/* webpackChunkName: "khan-el-khalilli-market" */ '../views/blog/KhanKhalilliMarket.vue'),
  },
  {
    path: '/information/kharga-oasis',
    name: 'KhargaOasis',
    component: () => import(/* webpackChunkName: "kharga-oasis" */ '../views/blog/KhargaOasis.vue'),
  },
  {
    path: '/information/the-new-alexandria-library',
    name: 'AlexandriaLibrary',
    component: () => import(/* webpackChunkName: the-new-alexandria-library" */ '../views/blog/AlexandriaLibrary.vue'),
  },
  {
    path: '/information/pompeys-pillar',
    name: 'PompeyPillar',
    component: () => import(/* webpackChunkName: pompeys-pillar" */ '../views/blog/PompeyPillar.vue'),
  },
  {
    path: '/information/the-high-dam',
    name: 'HighDam',
    component: () => import(/* webpackChunkName: the-high-dam" */ '../views/blog/HighDam.vue'),
  },
  {
    path: '/information/the-pyramids-of-guiza',
    name: 'PyramidsGuiza',
    component: () => import(/* webpackChunkName: the-pyramids-of-guiza" */ '../views/blog/PyramidsGuiza.vue'),
  },
  {
    path: '/information/luxor',
    name: 'Luxor',
    component: () => import(/* webpackChunkName: luxor" */ '../views/blog/Luxor.vue'),
  },
  {
    path: '/information/madinet-habu',
    name: 'MadinetHabu',
    component: () => import(/* webpackChunkName: madinet-habu" */ '../views/blog/MadinetHabu.vue'),
  },
  {
    path: '/information/memphis',
    name: 'Memphis',
    component: () => import(/* webpackChunkName: memphis" */ '../views/blog/Memphis.vue'),
  },
  {
    path: '/information/сairo-archaeological-museum',
    name: 'СairoArchaeologicalMuseum',
    component: () => import(/* webpackChunkName: сairo-archaeological-museum" */ '../views/blog/СairoArchaeologicalMuseum.vue'),
  },
  {
    path: '/information/bahariya-oasis',
    name: 'BahariyaOasis',
    component: () => import(/* webpackChunkName: bahariya-oasis" */ '../views/blog/BahariyaOasis.vue'),
  },
  {
    path: '/information/oasis-egypt',
    name: 'OasisEgypt',
    component: () => import(/* webpackChunkName: oasis-egypt" */ '../views/blog/OasisEgypt.vue'),
  },
  {
    path: '/information/sakkara',
    name: 'Sakkara',
    component: () => import(/* webpackChunkName: "sakkara" */ '../views/blog/Sakkara.vue'),
  },
  {
    path: '/information/the-sharm-shiekh',
    name: 'SharmShiekh',
    component: () => import(/* webpackChunkName: the-sharm-shiekh" */ '../views/blog/SharmShiekh.vue'),
  },
  {
    path: '/information/siwa-oasis',
    name: 'SiwaOasis',
    component: () => import(/* webpackChunkName: siwa-oasis" */ '../views/blog/SiwaOasis.vue'),
  },
  {
    path: '/information/tal-el-amarna',
    name: 'TalAmarna',
    component: () => import(/* webpackChunkName: tal-el-amarna" */ '../views/blog/TalAmarna.vue'),
  },
  {
    path: '/information/the-dendera-temple',
    name: 'DenderaTemple',
    component: () => import(/* webpackChunkName: the-dendera-temple" */ '../views/blog/DenderaTemple.vue'),
  },
  {
    path: '/information/karnak-temple',
    name: 'KarnakTemple',
    component: () => import(/* webpackChunkName: karnak-temple" */ '../views/blog/KarnakTemple.vue'),
  },
  {
    path: '/information/kom-ombo-temple',
    name: 'KomOmboTemple',
    component: () => import(/* webpackChunkName: kom-ombo-temple" */ '../views/blog/KomOmboTemple.vue'),
  },
  {
    path: '/information/luxor-temple',
    name: 'LuxorTemple',
    component: () => import(/* webpackChunkName: luxor-temple" */ '../views/blog/LuxorTemple.vue'),
  },
  // ----------------------------------------------------------------------------------------
  {
    path: '/digital-catalog',
    name: 'DigitalCatalog',
    component: () => import(/* webpackChunkName: "digital-catalog" */ '../views/DigitalCatalog.vue'),
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about-us" */ '../views/About.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
