import Vue from 'vue';
import Vuetify from 'vuetify/lib';

import en from '../locales/en';
import es from '../locales/es';
import pt from '../locales/pt';
import fi from '../locales/fi';

Vue.use(Vuetify);

const lang = window.navigator.language.slice(0, 2);
const locales = ['en', 'es', 'pt', 'fi'];
const currentLang = locales.includes(lang) ? lang : 'es';

export default new Vuetify({
  theme: { dark: true },
  icons: {
    iconfont: 'mdiSvg',
  },
  breakpoint: {
    thresholds: {
      xs: 600,
      sm: 960,
      md: 1264,
      lg: 1904,
    },
    scrollBarWidth: 24,
  },
  lang: {
    locales: {
      en,
      es,
      pt,
      fi,
    },
    current: currentLang,
  },
});
