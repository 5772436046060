import pt from 'vuetify/es5/locale/pt';
import attractions from './pt/attractions';

export default {
  ...pt,

  attractions,

  mainNav: {
    destinations: 'Destinos',
    events: 'Eventos',
    travels: 'Viagens',
    information: 'Informação',
    catalog: 'Catálogo digital',
  },
  subscribe: {
    btn: 'Inscreva-se',
    placeholder: 'Endereço de e-mail',
  },
};
