<template>
  <div>
    <v-carousel
      show-arrows-on-hover
      :cycle="true"
      :interval="9000"
    >
      <v-carousel-item
        v-for="(img, name) in homeSlider"
        :key="name"
      >
        <v-parallax
          dark
          :src="img"
        ></v-parallax>
      </v-carousel-item>
    </v-carousel>

    <v-container>
      <v-row >
        <v-col cols="12" md="4">
          <h2 class="display-1 font-weight-500 mb-4">
            {{ $vuetify.lang.t('$vuetify.whyUs.title') }}
          </h2>
          <p>
            {{ $vuetify.lang.t('$vuetify.whyUs.p1') }}
          </p>
          <p>
            {{ $vuetify.lang.t('$vuetify.whyUs.p2') }}
          </p>
          <h3 class="mb-4">
            {{ $vuetify.lang.t('$vuetify.whyUs.h3') }}
          </h3>
          <p>
            {{ $vuetify.lang.t('$vuetify.whyUs.p3') }}
          </p>
          <p>
            {{ $vuetify.lang.t('$vuetify.whyUs.p4') }}
          </p>
          <p>
            {{ $vuetify.lang.t('$vuetify.whyUs.p5') }}
          </p>
          <p>
            {{ $vuetify.lang.t('$vuetify.whyUs.p6') }}
          </p>

          <v-expansion-panels accordion multiple>
            <v-expansion-panel>
              <v-expansion-panel-header color="black">
                {{ $vuetify.lang.t('$vuetify.whyUs.tab1.title') }}
              </v-expansion-panel-header>

              <v-expansion-panel-content class="mt-4">
                {{ $vuetify.lang.t('$vuetify.whyUs.tab1.text') }}
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header color="black">
                {{ $vuetify.lang.t('$vuetify.whyUs.tab2.title') }}
              </v-expansion-panel-header>

              <v-expansion-panel-content class="mt-4">
                {{ $vuetify.lang.t('$vuetify.whyUs.tab2.text') }}
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header color="black">
                {{ $vuetify.lang.t('$vuetify.whyUs.tab3.title') }}
              </v-expansion-panel-header>

              <v-expansion-panel-content class="mt-4">
                {{ $vuetify.lang.t('$vuetify.whyUs.tab3.text') }}
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>

        <v-col cols="12" md="8">
          <h2 class="display-1 font-weight-500 mb-4">
            {{ $vuetify.lang.t('$vuetify.search.title') }}
          </h2>

          <v-form class="mb-6">
            <div class="d-flex">
              <v-text-field
                label="search"
                clearable
                light
                solo
                flat
                hide-details
                :height="$vuetify.breakpoint.smAndUp ? 52 : 48"
                type="search"
                placeholder=""
                class="rounded-r-0"
              ></v-text-field>
              <v-btn
                light
                @click.prevent=""
                depressed
                :x-large="$vuetify.breakpoint.smAndUp"
                :height="$vuetify.breakpoint.smAndUp ? 52 : 48"
                type="submit"
                class="rounded-l-0"
              >
                {{ $vuetify.lang.t('$vuetify.search.btn') }}
              </v-btn>
            </div>
          </v-form>

          <v-divider class="mb-4"></v-divider>

          <h2 class="display-1 font-weight-500 mb-4">
            {{ $vuetify.lang.t('$vuetify.travel.titleCards') }}
          </h2>

          <v-row>
            <v-col
              v-for="(card, i) in cards"
              :key="i"
              cols="12"
              sm="6"
              xl="4"
            >
              <v-card
                :class="$vuetify.breakpoint.mdAndDown ? 'mx-auto' : ''"
                color="black"
              >
                <v-img
                  height="250"
                  :src="card.img"
                ></v-img>

                <v-card-title class="flex justify-space-between align-center">
                  <div
                    v-html="$vuetify.lang.t(card.title)"
                    style="width: calc(100% - 36px)"
                  ></div>
                  <v-btn
                    icon
                  >
                    <v-icon size="24px">
                      {{ 'mdi-share-variant' }}
                    </v-icon>
                  </v-btn>
                </v-card-title>

                <v-divider class="mx-4 mb-4"></v-divider>

                <v-row no-gutters justify="space-between" class="px-4">
                  <v-card-text class="col-auto">
                    <div class="caption">
                      {{ $vuetify.lang.t('$vuetify.card.from') }}
                    </div>

                    <div class="headline">
                      {{ card.price }}€
                    </div>

                    <div class="caption">
                      {{ card.day }} {{ $vuetify.lang.t('$vuetify.card.days') }} /
                      {{ card.night }} {{ $vuetify.lang.t('$vuetify.card.nights') }}
                    </div>
                  </v-card-text>

                  <v-card-actions>
                    <v-btn
                      :to="card.link"
                      tag="a"
                      color="red darken-1"
                    >
                      {{ $vuetify.lang.t('$vuetify.card.more') }}
                    </v-btn>
                  </v-card-actions>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-parallax
      :height="$vuetify.breakpoint.smAndUp ? 400 : 300"
      dark
      src="../assets/home/parallaxSubscribe.jpg"
    >
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          class="text-center"
          cols="12"
          sm="8"
          md="6"
        >

          <v-form>
            <div class="d-flex">
              <v-text-field
                label="Solo"
                solo
                flat
                hide-details
                :height="$vuetify.breakpoint.smAndUp ? 52 : 48"
                type="email"
                :placeholder="$vuetify.lang.t('$vuetify.subscribe.placeholder')"
                class="rounded-r-0"
              ></v-text-field>
              <v-btn
                @click.prevent=""
                depressed
                :x-large="$vuetify.breakpoint.smAndUp"
                :height="$vuetify.breakpoint.smAndUp ? 52 : 48"
                type="submit"
                class="rounded-l-0"
              >
                {{ $vuetify.lang.t('$vuetify.subscribe.btn') }}
              </v-btn>
            </div>
          </v-form>
        </v-col>
      </v-row>
    </v-parallax>
  </div>
</template>

<script>
// @ is an alias to /src
import { homeSlider, homeCards } from '../scripts/images';

export default {
  name: 'Home',
  components: {},
  data: () => ({
    cards: [
      {
        title: '$vuetify.tours.egypt.egyptAndMarsaAlam.title',
        price: '1397',
        day: 11,
        night: 10,
        img: homeCards.egypt,
        link: '/destinations/egypt/egypt-and-marsa-alam',
      },
      {
        title: '$vuetify.tours.egypt.egyptAndMarsaAlam.title',
        price: '1450',
        day: 8,
        night: 7,
        img: homeCards.egypt,
        link: '/destinations/egypt/egypt-and-marsa-alam',
      },
      {
        title: '$vuetify.tours.egypt.egyptAndMarsaAlam.title',
        price: '1395',
        day: 9,
        night: 8,
        img: homeCards.egypt,
        link: '/destinations/egypt/egypt-and-marsa-alam',
      },
      {
        title: '$vuetify.tours.egypt.egyptAndMarsaAlam.title',
        price: '1125',
        day: 7,
        night: 7,
        img: homeCards.egypt,
        link: '/destinations/egypt/egypt-and-marsa-alam',
      },
      {
        title: '$vuetify.tours.egypt.egyptAndMarsaAlam.title',
        price: '1400',
        day: 8,
        night: 7,
        img: homeCards.egypt,
        link: '/destinations/egypt/egypt-and-marsa-alam',
      },
      {
        title: '$vuetify.tours.egypt.egyptAndMarsaAlam.title',
        price: '954',
        day: 12,
        night: 11,
        img: homeCards.egypt,
        link: '/destinations/egypt/egypt-and-marsa-alam',
      },
    ],
    homeSlider,
  }),
};
</script>
