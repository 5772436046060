import fi from 'vuetify/es5/locale/fi';
import attractions from '@/locales/fi/attractions';

export default {
  ...fi,

  attractions,

  mainNav: {
    destinations: 'Kohteet',
    events: 'Tapahtumat',
    travels: 'Matkat',
    information: 'Tiedot',
    catalog: 'Digitaalinen luettelo',
  },
  subscribe: {
    btn: 'Tilaa',
    placeholder: 'Sähköpostiosoite',
  },
};
