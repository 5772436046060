<template>
  <v-app>
    <v-card
      class="overflow-hidden"
    >
      <header class="black header">
        <v-container class="header__container">
          <v-row dense class="mb-4">
            <v-app-bar-nav-icon
              @click="drawer = !drawer"
              v-if="$vuetify.breakpoint.xsOnly"
            ></v-app-bar-nav-icon>

            <v-spacer></v-spacer>

            <div class="lang">
              <v-btn
                v-for="(lang, i) in langs"
                :key="i"
                light
                fab
                small
                :width="$vuetify.breakpoint.xsOnly ? 32 : ''"
                :height="$vuetify.breakpoint.xsOnly ? 32 : ''"
                @click="changeLocale(lang)"
                :class="i === langs.length - 1 ? '' : 'mr-2'"
              >
                <img
                  :src="flags[lang]"
                  alt=""
                  :width="$vuetify.breakpoint.xsOnly ? 28 : 36"
                  :height="$vuetify.breakpoint.xsOnly ? 28 : 36"
                >
              </v-btn>
            </div>
          </v-row>

          <router-link to="/" tag="a" class="logo" v-ripple>
            <img class="logo__img" src="./assets/logo-w.png" alt="" width="" height="">
          </router-link>

          <div class="phone">
            <v-btn
              v-for="phone in phones"
              :key="phone"
              tag="a"
              :href="`tel:+34${phone}`"
              class="mb-2"
              :width="$vuetify.breakpoint.xsOnly ? 'auto' : 180"
            >
              <v-icon size="16px" class="mr-2">
                mdi-phone
              </v-icon>
              +34 {{ phone }}
            </v-btn>
          </div>

          <nav
            v-if="$vuetify.breakpoint.smAndUp"
            class="main-nav"
          >
            <v-menu
              open-on-hover
              down
              offset-y
              transition="slide-y-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  large
                  tile
                  tag="a"
                  color="black"
                >
                  {{ $vuetify.lang.t('$vuetify.mainNav.destinations') }}
                </v-btn>
              </template>

              <div class="d-flex mt-2">
                <v-list width="120px" flat>
                  <v-list-item
                    v-for="(item, i) in subMenu1"
                    :key="i"
                    :to="item.path"
                  >
                    <v-list-item-title>
                      {{ $vuetify.lang.t(item.name) }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
                <v-list width="160px" flat>
                  <v-list-item
                    v-for="(item, i) in subMenu2"
                    :key="i"
                    :to="item.path"
                  >
                    <v-list-item-title>
                      {{ $vuetify.lang.t(item.name) }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </div>
            </v-menu>

            <v-btn
              large
              tile
              tag="a"
              color="black"
              v-for="(item, i) in mainNav"
              :key="i"
              :to="item.path"
            >
              {{ $vuetify.lang.t(item.name) }}
            </v-btn>
          </nav>
        </v-container>
      </header>

      <v-main class="overflow-y-auto">
        <router-view/>
      </v-main>

      <v-footer
        color="black"
        padless
      >
        <v-container>
          <v-row
            justify="center"
            no-gutters
          >
            <v-col cols="12" sm="6" md="4" class="text-center">
              <p class="title mb-2 mt-2">
                {{ $vuetify.lang.t('$vuetify.footer.contacts') }}
              </p>

              <v-btn
                v-for="(item, i) in contacts"
                :key="i"
                class="my-2"
                text
                tag="a"
                :to="item.link"
                small
                block
              >
                {{ $vuetify.lang.t(item.title) }}
              </v-btn>

              <v-divider v-if="$vuetify.breakpoint.xsOnly"></v-divider>
            </v-col>

            <v-col cols="12" sm="6" md="4" class="text-center">
              <p class="title mb-2 mt-2">
                {{ $vuetify.lang.t('$vuetify.footer.info') }}
              </p>

              <v-btn
                v-for="(item, i) in footerInfo"
                :key="i + item.title"
                class="my-2"
                text
                tag="a"
                :to="item.link"
                small
                block
              >
                {{ $vuetify.lang.t(item.title) }}
              </v-btn>
              <v-divider v-if="$vuetify.breakpoint.xsOnly"></v-divider>
            </v-col>

            <v-col cols="12" md="4" class="text-center">
              <p class="title mb-2 mt-2">
                {{ $vuetify.lang.t('$vuetify.footer.follow') }}
              </p>
              <v-btn
                v-for="(item, i) in social"
                :key="i + item.link"
                :href="item.link"
                class="mx-2"
                target="_blank"
                icon
                fab
                small
                color="grey lighten-5"
              >
                <v-icon>
                  {{ item.icon }}
                </v-icon>
              </v-btn>
            </v-col>

            <v-col
              class="text-center white--text"
              cols="12"
            >
              {{ new Date().getFullYear() }} — <strong>Aviaral Travel</strong>
            </v-col>
          </v-row>
        </v-container>
      </v-footer>

      <v-navigation-drawer
        v-model="drawer"
        absolute
        temporary
        color="grey darken-4"
      >
        <v-list
          nav
        >
          <v-list-group
            :value="false"
          >
            <template v-slot:activator>
              <v-list-item-title>
                {{ $vuetify.lang.t('$vuetify.mainNav.destinations') }}
              </v-list-item-title>
            </template>

            <v-divider></v-divider>

            <v-list-item
              v-for="(item, i) in subMenu1"
              :key="i + item.name"
              :to="item.path"
            >
              <v-list-item-title>
                {{ $vuetify.lang.t(item.name) }}
              </v-list-item-title>
            </v-list-item>

            <v-list-item
              v-for="(item, i) in subMenu2"
              :key="i + item.name"
              :to="item.path"
            >
              <v-list-item-title>
                {{ $vuetify.lang.t(item.name) }}
              </v-list-item-title>
            </v-list-item>
            <v-divider></v-divider>
          </v-list-group>

          <v-list-item-group>
            <v-list-item
              v-for="(item, i) in mainNav"
              :key="i"
              :to="item.path">
              <v-list-item-title>
                {{ $vuetify.lang.t(item.name) }}
              </v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
    </v-card>
  </v-app>
</template>

<script>
import { flags } from './scripts/images';

export default {
  name: 'App',

  components: {
    // HelloWorld,
  },

  data: () => ({
    drawer: null,
    mainNav: [
      {
        name: '$vuetify.mainNav.events',
        path: '/events',
      },
      {
        name: '$vuetify.mainNav.travels',
        path: '/travels',
      },
      {
        name: '$vuetify.mainNav.information',
        path: '/information',
      },
      {
        name: '$vuetify.mainNav.catalog',
        path: '/digital-catalog',
      },
    ],
    subMenu1: [
      {
        name: '$vuetify.destinations.egypt',
        path: '/destinations/egypt',
      },
      {
        name: '$vuetify.destinations.turkey',
        path: '/destinations/turkey',
      },
      {
        name: '$vuetify.destinations.india',
        path: '/destinations/india',
      },
    ],
    subMenu2: [
      {
        name: '$vuetify.destinations.jordan',
        path: '/destinations/jordan',
      },
      {
        name: '$vuetify.destinations.emirates',
        path: '/destinations/emirates',
      },
      {
        name: '$vuetify.destinations.israel',
        path: '/destinations/israel',
      },
    ],
    langs: ['en', 'es', 'pt', 'fi'],
    flags,
    phones: ['617784799', '617784583'],
    social: [
      {
        icon: 'mdi-facebook',
        link: 'https://web.facebook.com/Aviaral-travel-106548794707276',
      },
      {
        icon: 'mdi-twitter',
        link: 'https://web.facebook.com/Aviaral-travel-106548794707276',
      },
      {
        icon: 'mdi-instagram',
        link: 'https://web.facebook.com/Aviaral-travel-106548794707276',
      },
      {
        icon: 'mdi-youtube',
        link: 'https://www.youtube.com/channel/UC8oy1cC0eeK82BiGPBmiZ9g',
      },
    ],
    footerInfo: [
      {
        title: '$vuetify.footer.returnPolicy',
        link: '/return-policy',
      },
      {
        title: '$vuetify.footer.insurance',
        link: '/insurance',
      },
    ],
    contacts: [
      {
        title: '$vuetify.footer.about',
        link: '/contact-us',
      },
      {
        title: '$vuetify.footer.contact',
        link: '/contact-us',
      },
    ],
  }),
  methods: {
    changeLocale(lang) {
      this.$vuetify.lang.current = lang;
    },
  },
};
</script>

<style lang="scss">
.header {
  &__container {
    @media (min-width: 600px) {
      position: relative;
    }
  }
}
.logo {
  display: block;
  width: 165px;
  height: 190px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 24px;

  @media (min-width: 600px) {
    position: absolute;
    top: 12px;
    left: 12px;
    width: 205px;
    height: 236px;
  }

  @media (min-width: 960px) {
    width: 165px;
    height: 190px;
  }

  &--active {
    color: #ffffff !important;
  }

  &__img {
    max-width: 100%;
  }
}

.main-nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-left: calc(100% - 360px);

  @media (min-width: 960px) {
    padding-left: 180px;
  }

  @media (min-width: 1264px) {
    padding-left: 0;
  }
  //width: 100%;
}

.phone {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 296px;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 600px) {
    flex-wrap: wrap;
    justify-content: flex-end;
    max-width: 184px;
    margin-right: 0;
    margin-bottom: 16px;
  }

  @media (min-width: 960px) {
    margin-top: 24px;
    margin-bottom: 8px;
  }
}
</style>
